import React, { useState } from "react";

const App = () => {
  const [cards, setCards] = useState([
    "logo192.png",
    "https://upload.wikimedia.org/wikipedia/commons/a/ab/01_of_spades_A.svg",
    // "https://example.com/card3.jpg",
    // Add URLs for other cards
  ]);
  const [pickedCard, setPickedCard] = useState(null);

  const pickRandomCard = () => {
    if (cards.length > 0) {
      const randomIndex = Math.floor(Math.random() * cards.length);
      const picked = cards[randomIndex];
      setPickedCard(picked);
      setCards(cards.filter((card) => card !== picked));
    }
  };

  const resetCards = () => {
    setCards([
      "logo192.png",
      "https://upload.wikimedia.org/wikipedia/commons/a/ab/01_of_spades_A.svg",
      // "https://example.com/card3.jpg",
      // Add URLs for other cards
    ]);
    setPickedCard(null);
  };

  return (
    <div>
      <h1>Random Card Picker</h1>
      {pickedCard && <img src={pickedCard} alt="Picked Card" />}
      <button onClick={pickRandomCard}>Pick a Card</button>
      <button onClick={resetCards}>Start Fresh</button>
    </div>
  );
};

export default App;
